<template>
    <div>
        Nuevo trabajador
        {{esteTrab}}
    </div>
</template>

<script>
export default {

    props:{
        esteTrab:Object    
    }
}
</script>

<style>

</style>